<template>
  <div class="about h-100 onboarding_bg" id="qa-onboarding-view">
    <SystemAlert v-if="isSystemAlertEnabled" />
    <Header
      :step="step"
      :showNewsLetter="isNewsletter"
      @navigateToStep="navigateToStep"
      v-if="isLoading === false"
    />
    <Loader v-if="isLoading" />
    <div v-else>
      <Location
        v-if="step === 1"
        :step="step"
        @onUpdate="incrementStep"
      ></Location>
      <DateofBirth
        v-if="step === 2"
        :step="step"
        @onUpdate="incrementStep"
      ></DateofBirth>
      <Gender v-if="step === 3" :step="step" @onUpdate="incrementStep"></Gender>
      <Affiliation
        v-if="step === 4"
        :step="step"
        @onUpdate="incrementStep"
      ></Affiliation>
      <Experience
        v-if="step === 5"
        :step="step"
        @onUpdate="incrementStep"
      ></Experience>
      <Education
        v-if="step === 6"
        :step="step"
        :isNewsletter="isNewsletter"
        @onUpdate="incrementStep"
      ></Education>
      <Newsletter
        v-if="step === 7 && isNewsletter"
        :step="step"
        @onUpdate="incrementStep"
      ></Newsletter>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// mixins
import userStatus from "@/core/mixins/userStatus";
// components
import Location from "@/components/Onboarding/Location.vue";
import DateofBirth from "@/components/Onboarding/DateofBirth.vue";
import Gender from "@/components/Onboarding/Gender.vue";
import Affiliation from "@/components/Onboarding/Affiliation.vue";
import Experience from "@/components/Onboarding/Experience.vue";
import Education from "@/components/Onboarding/Education.vue";
import Newsletter from "@/components/Onboarding/Newsletter.vue";
import Loader from "@/components/WrapperComponents/Loader";
import Header from "@/components/Layout/OnboardingPageLayout/Header.vue";
import SystemAlert from "./SystemAlert.vue";

export default {
  mixins: [userStatus],
  data() {
    return {
      step: 1,
      isLoading: false,
      isNewsletter: false
    };
  },
  computed: {
    ...mapGetters([
      "allProfile",
      "userStatus",
      "allConfig",
      "getOnboardingStatus",
      "isSystemAlertEnabled"
    ])
  },
  components: {
    Loader,
    Header,
    Location,
    DateofBirth,
    Gender,
    Affiliation,
    Experience,
    Education,
    Newsletter,
    SystemAlert
  },
  created() {
    this.getNewsletterStatus();
    this.$emit("update:layout", "main");
  },
  mounted() {
    this.$nextTick(async () => {
      // Check the keycloak object is present. If not redirect to home page
      if (
        this.$keycloak &&
        this.$keycloak.idTokenParsed &&
        this.$keycloak.idTokenParsed.sub
      ) {
        this.checkOnboardingStatus();
      } else {
        this.$router.push({ name: "Home" });
      }
    });
  },
  methods: {
    navigateToStep(newStep) {
      if (newStep < this.step) {
        this.step = newStep;
      }
    },
    incrementStep() {
      this.step++;
    },
    async getNewsletterStatus() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("getProfile");
        this.isNewsletter =
          this.allProfile.is_news_letter === true ? false : this.isNewsletter;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
